.project-parent-container {
    width: 100%;
    /* padding: 128px 0 0 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: var(--background-color-off-white); */
}
.project-intro-parent-container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding-top: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.project-intro-bg-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* background-image: url("http://elitenooks.com/uploads/pictures/packages/rooms/living_and_dining/package_b/VIEW%202.jpeg"); */
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* opacity: 0.2; */
}
.project-summary-container {
    position: relative;
    flex: 1;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10%;
    padding: 8px;
    /* background-color: var(--background-color-white); */
    border-radius: 4px;
}
.project-summary-container .project-details-container {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    text-align: right;
}
.project-summary-container .project-details-container p {
    margin-bottom: 0;
}
.project-summary-container .project-details-container .project-title {
    font-size: 3em;
    color: var(--color-primary-main);
}
.project-size, .project-package, .project-location-container {
    font-size: 1.1em;
    margin: 8px 0 16px 0;
}
.project-summary-container .project-details-container .project-time {
    font-size: 1em;
    color: #fff;
    background-color: #5f1247;
    border-radius: 8px;
    padding: 4px 11px;
    margin: 16px 0;
}
.project-summary-container .project-details-container .project-package a {
    text-decoration: none;
    font-weight: 600;
    color: var(--color-primary-main);
}
.project-summary-container .project-details-container .project-location-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.project-summary-container .project-details-container .project-details-icon {
    margin-right: 8px;
}
.project-key-picture-parent-container {
    /* width: 100%; */
    min-height: min(50vw, 50vh);
}
.project-key-picture-container {
    /* position: relative; */
    width: 100%;
    height: 100%;
}
.project-key-picture {
    width: 100%;
    height: 100%;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 4px;
}
.project-other-details-container {
    position: relative;
    width: 100%;
    max-width: 1000px;
    /* align-self: start; */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr) minmax(150px, 1fr));
    gap: 1px;
    margin: 72px 24px;
    background-color: #0002;
}
.project-other-details-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-primary-main);
    background-color: var(--background-color-white);
    padding: 36px 8px;
}
.project-other-details-item .project-other-details-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 24px;
}
.project-other-details-item p {
    font-size: 1em;
    font-weight: 500;
    margin: 0;
}
.project-room-details-parent-container {
    width: 100%;
    padding: 96px 0 0 0;
    /* background-color: var(--background-color-off-white); */
}
.project-room-details-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.project-room-details-picture-gallery {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%/3), 1fr));
    grid-auto-flow: dense;
    grid-gap: 0.75rem;
    padding: 96px 32px;
}
.project-room-details-picture-gallery .section-title {
    grid-column: span 2;
    align-items: center;
}
.project-room-details-picture-gallery.project-views .section-title {
    grid-column: -3 / -1;
}
.project-room-details-picture {
    /* aspect-ratio: 1; */
    border-radius: 8px;
    background-color: var(--background-color-white);
    overflow: hidden;
    cursor: pointer;
}
.project-room-details-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    scale: 1.25;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
}
.project-room-details-picture:hover img {
    scale: 1;
}
.explore-more-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* opacity: 0.5; */
    color: var(--color-primary-main);
    background-color: transparent;
}
.explore-more-button p {
    font-size: 1.15em;
    color: var(--color-primary-main);
}
.explore-more-button .explore-more-icon {
    /* animation: ; */
}
.swiper-button-next, .swiper-button-prev {
    width: 52px;
    height: 52px;
    border: solid 2px #fffa;
    border-radius: 50%;
    color: var(--color-secondary-main);
    background-color: var(--color-primary-main);
    font-weight: 600;
    /* text-shadow: 0 1px 3px #fff; */
}
.swiper-button-next::after, .swiper-button-prev::after {
    font-size: 20px;
}
/* .project-share-button {
    margin: 16px 0 0 0;
} */
@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    .project-intro-bg-container {
        background-attachment: local;
    }   
}