.contact-parent-container {
    width: 100%;
    padding: 128px 10%;
    /* background-color: var(--background-color-off-white); */
}
.contact-parent-container .contact-inner-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}
.contact-inner-container .section-title {
    margin: auto;
    align-items: center;
}
.contact-inner-container .section-title .section-title-text {
    /* font-family: Raleway; */
    /* font-size: 3em; */
    line-height: 0.75em;
}
.contact-parent-container .social-media-handles-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(64px, 1fr) minmax(64px, 1fr));
    gap: 24px;
    margin: 32px 0;
}
.contact-parent-container .social-media-handles-container .social-media-handle {
    background-color: var(--color-primary-main);
    box-shadow: 0 0 10px var(--color-primary-translucent);
    border-radius: 8px;
    padding: 8px;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 80px;
    scale: 1;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
}
.contact-parent-container .social-media-handles-container .social-media-handle:hover {
    scale: 1.15;
}
.contact-parent-container .social-media-handles-container .social-media-handle .social-media-handle-icon {
    color: var(--color-secondary-main);
    text-decoration: none;
    width: 100%;
    height: 100%;
    max-width: 48px;
    max-height: 48px;
}
.contact-form-and-details-parent-container {
    width: 100%;
    /* display: grid; */
    /* grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr)); */
    column-gap: 48px;
    row-gap: 48px;
    margin: 82px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-around;
    flex-wrap: wrap-reverse;
}
.contact-details-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(150px, 100%), 1fr) minmax(min(150px, 100%), 1fr));
    gap: 1px;
    margin: 48px 0 0 0;
    background-color: var(--color-primary-translucent);
}
.contact-details-container .contact-detail {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    padding: 24px 16px;
    overflow: hidden;
    cursor: pointer;
    background-color: var(--background-color-white);
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
}
.contact-details-container .contact-detail:hover {
    background-color: #fff;
}
.contact-details-container .contact-detail .contact-detail-icon {
    width: 42px;
    height: 42px;
    margin: 0 0 8px 0;
    color: var(--color-primary-light);
}
.contact-details-container .contact-detail .contact-details-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.contact-details-container .contact-detail .contact-detail-label {
    font-size: 1.25em;
    font-weight: 600;
    margin: 0 0 8px 0;
}
.contact-details-container .contact-detail .contact-detail-title {
    font-size: 1em;
    margin: 0 0 2px 0;
}
.contact-details-container .contact-detail .contact-detail-subtitle {
    font-size: 0.8em;
    color: var(--color-primary-light);
    margin: 0;
}
.contact-details-form {
    flex: 1;
    min-width: min(300px, 100%);
    max-width: 500px;
}
.contact-details-form form {
    width: 100%;
    max-width:400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.contact-details-form .submit_button {
    min-height: 36px;
    min-width: 128px;
}
.contact-details-form .contact-details-form-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin: 0 0 16px 0;
}
.contact-details-form .contact-details-form-item label {
    /* margin: 0 0 8px 0; */
    color: var(--color-primary-main);
}
.contact-details-form .contact-details-form-item .contact-form-input,
.contact-details-form .contact-details-form-item textarea {
    margin: 0 0 8px 0;
    width: 100%;
    background-color: var(--background-color-off-white);
}
.contact-details-form textarea {
    padding: 16px;
}
.contact-details-acknowledge-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}
.contact-details-acknowledge-input-container input[type=checkbox] {
    width: auto;
    margin: 0 12px 0 0;
    accent-color: var(--color-primary-main);
}
.contact-details-acknowledge-input-container p {
    margin: 0;
}
.contact-get-in-touch-container {
    flex: 1;
    width: 100%;
    min-width: min(300px, 100%);
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: start;
    /* margin: 0 auto; */
}
.contact-get-in-touch-container .contact-get-in-touch-title {
    font-size: 2em;
    font-weight: 600;
    text-align: left;
}
.contact-get-in-touch-container .contact-get-in-touch-subtitle {
    font-size: 1em;
    text-align: left;
}