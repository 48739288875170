.packages-parent-container {
    width: 100%;
    padding: 82px 24px;
    /* background-color: var(--background-color-off-white); */
}
.packages-inner-container {
    display: flex;
    flex-direction: column;
    gap: 64px;
    align-items: center;
    justify-content: center;
}
.packages-section-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.packages-section-title-text {
    font-size: 4em;
    /* letter-spacing: 0.05em; */
    margin: 0;
}
.packages-section-subtitle-text {
    font-size: 0.8em;
    color: var(--color-primary-dark);
}
.packages-grid-view {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
    gap: 36px;
    display: none;
}
.packages-grid-view .packages-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 24px;
    border: solid 1px var(--color-primary-light);
}
.packages-table {
    width: 100%;
    /* table-layout: fixed; */
}
.packages-table thead th {
    border: solid 1px var(--background-color-off-white);
    border-collapse: collapse;
    background-color: var(--color-primary-main);
}
.packages-table thead th:nth-child(1) {
background-color: transparent;
}
.package-table-header {
    /* background-color: var(--color-primary-main); */
}
.packages-table .packages-table-section-title {
    color: var(--color-primary-main);
    background-color: #0001;
    /* border: solid 1px #fff; */
    border-collapse: collapse;
    padding: 8px 0;
    margin: 16px 0 16px 0;
    border: solid 1px #0002;
    border-collapse: collapse;
}
.packages-table .package-feature-title {
    /* background-color: #0001; */
}
/* .packages-table .packages-table-body th, */
/* .packages-table .packages-table-body tr, */
.packages-table .packages-table-body tr td {
    border: solid 1px #0002;
    border-collapse: collapse;
}
.packages-table .packages-table-body tr td:nth-child(1) {
    border: none;
    text-align: right;
    padding: 0 16px 0 0;
}
.packages-table .packages-table-body th,
.packages-table .packages-table-body td {
    padding: 12px 4px;
}
.packages-table .packages-table-body td p {
    margin: 0;
}
.packages-table .packages-table-body tr td:nth-child(2),
.packages-table .packages-table-body tr td:nth-child(3),
.packages-table .packages-table-body tr td:nth-child(4) {
    width: 33.33%;
}

.packages-grid-view .packages-grid-item .package-icon,
.packages-table .package-icon {
    width: 100%;
    max-width: 128px;
    height: 100%;
    max-height: 128px;
    padding: 24px;
    border-radius: 4px;
    background-color: var(--color-primary-main);
    opacity: 0.8;
}
.packages-table .package-icon {
    margin-top: 16px;
}
.packages-grid-view .packages-grid-item .package-title,
.packages-table  .package-title {
    font-size: 1em;
    margin: 12px 0;
    font-weight: 600;
}
.packages-table  .package-title {
    color: var(--color-secondary-main);
    margin-bottom: 32px;
}
.packages-grid-view .packages-grid-item .package-description,
.packages-table  .package-description  {
    font-size: 0.8em;
    margin: 0;
}
.package-includes-icon,
.package-excludes-icon {
    width: 16px;
    height: 16px;
}
.package-includes-icon {
    color: var(--color-primary-main);
}
.package-excludes-icon {
    color: var(--color-primary-main);
    opacity: 0.25;
}

@media screen and (max-width: 800px) {
    /* Small Screen */
    .packages-table {
        table-layout: fixed;
    }
}