.walk-in-homes-parent-container {
    width: 100%;
    padding: 82px 32px;
    background-color: var(--background-color-white);
}
.walk-in-homes-inner-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(350px,100%), 1fr));
    /* gap: 64px; */
    align-items: center;
    justify-content: space-around;
}
.walk-in-homes-side-view {
    position: relative;
    flex: 1;
    background-color: #fff1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.walk-in-homes-side-view .walk-in-homes-background-color-block {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 40%;
    background-color: var(--color-primary-main);
}
.walk-in-homes-side-view .walk-in-homes-image-preview {
    width: 60%;
    height: 30vh;
    margin: auto;
    z-index: 2;
    background-color: var(--color-primary-light);
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    object-fit: cover;
    object-fit: cover;
    background-position: center;
    background-attachment:fixed;
    background-repeat: no-repeat;
}
.walk-in-homes-section-title {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 24px 0;
}
.walk-in-homes-section-title-text {
    font-size: 4em;
    /* letter-spacing: 0.05em; */
    line-height: 1em;
    margin: 0 0 32px 0;
}
.walk-in-homes-section-subtitle {
    font-size: 1em;
    /* letter-spacing: 0.25em; */
    margin: 0;
}
.walk-in-homes-section-body {
    font-size: 1em;
    text-align: center;
}
.walk-in-homes-to-about-us-cta {
    text-decoration: none;
    margin-bottom: 32px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--color-primary-main);
}
.walk-in-homes-to-about-us-cta p {
    margin: 0 8px 0 0;
}
.walk-in-homes-to-about-us-cta .walk-in-homes-to-about-us-cta-icon {
    width: 20px;
    height: 20px;
}
.walk-in-homes-highlights-grid-view {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 150px);
    gap: 16px;
    justify-content: center;
}
.walk-in-homes-highlight-grid-item {
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-dark);
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    object-fit: cover;
    scale: 1;
    z-index: 1;
    box-shadow: 0px 0px 0px #000;
    transition: scale 300ms, z-index 150ms;
    transition-timing-function: ease-in-out;
}
.walk-in-homes-highlight-grid-item.blank-grid:hover {
    scale: 1.5;
    z-index: 2;
    box-shadow: 0px 0px 22px #000;
}
.walk-in-homes-highlight-icon-container {
    border-radius: 4px;
    
}
.walk-in-homes-highlight-icon-container .walk-in-homes-highlight-icon {
    width: 36px;
    height: 36px;
    color: #fff;
}
.walk-in-homes-highlight-title {
    font-size: 1em;
    font-weight: 600;
    color: var(--color-secondary-main);
    margin: 8px 0 0 0;
    text-align: left;
}
.walk-in-homes-highlight-body {
    font-size: 0.8em;
    color: var(--color-primary-dark);
    text-align: left;
}
.walk-in-homes-window-parallax-container {
    position: relative;
    width: 100%;
    max-height: 550px;
    aspect-ratio: 1 / 2;
}
.walk-in-homes-window-parallax-front {
    background-position: center;
    background-size: contain;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-repeat: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    aspect-ratio: 1 / 2;
    margin: auto;
}
.walk-in-homes-window-parallax-back {
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    aspect-ratio: 1 / 2;
    margin: auto;
}
@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    .walk-in-homes-window-parallax-back {
        background-attachment: local;
    }   
}