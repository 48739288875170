.PrivacyPolicyView {
   padding: 128px 0 128px 0;
   min-height: 100vh;
   text-align: left;
}
.PrivacyPolicyView .title {
   text-align: center;
}
.PrivacyPolicyView .sub-title {
   text-align: center;
}
.PrivacyPolicyBody {
    margin-top: 64px;
    text-align: justify;
}
.PrivacyPolicyBody h2 {
    font-size: 1.5em;
    margin-top: 42px;
}
.PrivacyPolicyBody br {
    margin-bottom: 24px;
}