
.Loader {
  padding: 128px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color-white);
}
.Loader .Title {
  font-size: 1.25rem;
  margin: 16px 0 0 0;
}
.Loader .Subtitle {
  font-size: 0.8rem;
  margin: 8px 0 32px 0;
}
.Loader .Graphics-container {
  margin-bottom: 16px;
  width: 100px;
  height: 100px;
  /* background-image: url('../../asset/icon/no_content.png'); */
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-repeat: contain;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.5;
}
.Loader Button {
  margin-top: 0 !important;
}