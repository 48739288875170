
.footer {
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
color: var(--color-secondary-main);
background-color: var(--color-primary-dark);
overflow: hidden;
}
.footer-inner-container {
  width: 100%;
  padding: 64px 32px 32px 48px;
  display: flex;
  flex-direction: column;
}
.footer .Cta {
/* letter-spacing: 7px; */
margin-top: 16px;
margin-bottom: 42px;
}
.footer .Copyright-text {
  margin: 0;
}
.footer-grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
}
.footer-grid-item {
  display: inline-flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
.footer-grid-item-label {
  font-size: 3em;
  /* letter-spacing: 0.05em; */
  color: var(--color-secondary-main);
}
.footer .footer-bottom-bar {
  width: calc(100% - 32px);
  padding: 20px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0.60em;
  opacity: 0.5;
  border-top: solid 1px #fff5;
}
.footer .footer-bottom-bar p {
  margin: 0;
}
.footer-social-media-links-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}
.footer-social-media-links-container a {
  text-decoration: none;
  padding: 4px;
  color: var(--color-secondary-main);
}
.footer-logo {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-bottom: 32px;
}
.footer-logo img {
  width: 84px;
  height: 84px;
}
.footer-logo p {
  font-size: 1.5em;
  font-family: Raleway, sans-serif;
  margin: 0 0 0 16px;
}
.footer-tag-line {
  font-size: 3em;
  /* letter-spacing: 0.2em; */
  padding: 24px 0 36px 0;
  color: #fff5;
}
.footer-contact-links-container {
  display: inline-flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
.footer-contact-links-container a {
  display: inline-flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  text-decoration: none;
  color: var(--color-secondary-main);
}
.footer-contact-links-container a p {
  text-align: left;
  margin-left: 16px;
  color: var(--color-secondary-main);
}
.footer .Copyright-text {
  text-decoration: none;
  font-size: 1.5em;
  color: var(--color-secondary-main);
}

@media screen and (max-width: 800px) {
  /* Small Screen */
  .footer .footer-bottom-bar {
    width: 100%;
    padding: 20px 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 800px) {
  /* Large Screen */
  
}