.project-details-cta-for-callback {
    position: relative;
    width: 100%;
    padding: 92px 32px;
    /* border-radius: 16px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-dark);
    gap: 58px;
    text-align: left;
    overflow: hidden;
}
.project-details-cta-for-callback-circles-bg-container {
    position: absolute;
    width: max(75vw,75vh);
    height: max(75vw,75vh);
    top: min(-40vw,-40vh);
    scale: 1.35;
    rotate: 65deg;
    opacity: 0.25;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
}
.project-details-cta-for-callback:hover .project-details-cta-for-callback-circles-bg-container {
    scale: 2;
    rotate: 45deg;
    /* opacity: 0.5; */
}
.project-details-cta-for-callback-circles-bg-container .circles-bg-large {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 10vw var(--color-primary-light);
}
.project-details-cta-for-callback-circles-bg-container .circles-bg-med {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 75%;
    height: 75%;
    border-radius: 40%;
    box-shadow: 0 0 10vw var(--color-primary-light);
}
.project-details-cta-for-callback-circles-bg-container .circles-bg-small {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 50%;
    height: 50%;
    border-radius: 30%;
    box-shadow: 0 0 10vw var(--color-primary-light);
}
.project-details-cta-for-callback .icon {
    position: absolute;
    left: 0;
    width: min(400px, 110vw);
    height: min(400px, 110vw);
    color: var(--color-secondary-main);
    border-radius: 42px;
    opacity: 0.05;
}
.project-details-cta-for-callback .cta-text-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.project-details-cta-for-callback .cta-text-container .title {
    font-size: 4em;
    /* letter-spacing: 0.05em; */
    line-height: 1em;
    margin-bottom: 32px;
    text-align: left;
    color: var(--color-secondary-main);
}
.project-details-cta-for-callback .cta-text-container .body {
    font-size: 1.25em;
    margin: 0;
    color: var(--color-secondary-main);
    opacity: 0.75;
}