.header {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
  position: fixed;
  background-color: var(--background-color-off-white);
  background-attachment: fixed;
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-repeat: contain;
  background-position: center;
  background-repeat: repeat;
  transition-duration: 350ms;
  transition-timing-function: ease-in-out;
}
.header.opaque {
  background-color: transparent;
}
.header .Back-button {
  width: 32px;
  height: 32px;
  /* background-image: url('../../asset/icon/back_arrow.svg'); */
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-repeat: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.header .Profile-button {
  width: 32px;
  height: 32px;
  /* background-image: url('../../asset/icon/profile_placeholder.svg'); */
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-repeat: contain;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.header .header-logo-container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.header .header-logo-container .header-logo-inner-div {
  position: relative;
  width: 48px;
  height: 48px;
}
.header .header-logo-container .brand-logo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.header .header-logo-container .brand-name-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin: 0 0 0 16px;
}
.header .header-logo-container .brand-name {
  font-size: 1.5em;
  margin: 0;
}
.header .header-logo-container .brand-subtitle {
  font-size: 0.8em;
  font-weight: 600;
  opacity: 0.75;
  margin: 0;
}
.header.light .header-logo-container .brand-name {
  color: var(--color-secondary-main);
}
.header.light .header-logo-container .brand-subtitle {
  color: var(--color-secondary-main);
}
.header-main-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.header-button {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.header-rhs-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 16px;
}
.header-rhs-cta p {
  margin: 0;
}
.header-social-media-links-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
.header-social-media-links-container a {
  text-decoration: none;
  padding: 4px;
  color: var(--color-primary-main);
}
.header.light .header-social-media-links-container a {
  color: var(--color-secondary-main);
}

.header-menu-toggle-button {
  min-height: 48px;
}
.header-hover-menu {
  width: 100%;
  height: 100dvh;

  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  padding: 0 0 32px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  opacity: 0;
  color: var(--color-secondary-main);
  background-color: var(--color-primary-dark);
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}
.header-hover-menu.open {
  right: 0;
  opacity: 1;
}
.header-hover-menu .header-main-menu {
  display: flex;
  flex-direction: column;
}
.header-hover-menu .header-main-menu .header-button {
  color: var(--color-secondary-main);
  padding: 16px 0;
}
.header-hover-menu .header-rhs-cta {
  display: flex;
  flex-direction: column;
}
.header-hover-menu .header-rhs-cta p {
  margin: 0 0 16px 0;
  color: var(--color-secondary-main);
}
.header-hover-menu .header-rhs-cta .header-social-media-links-container a {
  color: var(--color-secondary-main);
  background-color: var(--color-primary-main);
  padding: 12px;
  border-radius: 8px;
}
.header-hover-menu .header-menu-toggle-container {
  width: 100%;
  padding: 16px 0 16px 16px;
  text-align: right;
}
.header-hover-menu .header-menu-toggle-button .header-menu-toggle-icon {
  color: var(--color-secondary-main);
}
@media screen and (max-width: 800px) {
  /* Small Screen */
  .header-menu-toggle-container {
    display: block;
  }
  .large-screen-only {
    display: none;
  }
  .header {
    padding: 16px 0 16px 16px;
  }
}
@media screen and (min-width: 800px) {
  /* Large Screen */
  .small-screen-only {
    display: none;
  }
}
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  .header {
      background-attachment: local;
  }   
}