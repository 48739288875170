.why-choose-us-parent-container {
    width: 100%;
    padding: 82px 10%;
    /* background-color: var(--background-color-off-white); */
}
.why-choose-us-inner-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}
.why-choose-us-section-title {
    font-size: 4em;
    /* letter-spacing: 0.05em; */
    line-height: 1em;
    margin: 8px 0 32px 0;
}
.why-choose-us-to-about-us-cta {
    text-decoration: none;
    margin-bottom: 32px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--color-primary-main);
}
.why-choose-us-to-about-us-cta p {
    margin: 0 8px 0 0;
}
.why-choose-us-to-about-us-cta .why-choose-us-to-about-us-cta-icon {
    width: 20px;
    height: 20px;
}
.why-choose-us-highlights-grid-view {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
    gap: 36px;
    margin-top: 32px;
}
.why-choose-us-highlight-grid-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 24px;
    border: solid 1px var(--color-primary-translucent);
}
.why-choose-us-highlight-icon-container {
    padding: 24px 32px;
    margin: -42px 0 0 0;
    background-color: var(--color-primary-main);
    border-radius: 4px;
    scale: 1;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
}
.why-choose-us-highlight-grid-item:hover .why-choose-us-highlight-icon-container {
    scale: 1.25;
}
.why-choose-us-highlight-icon-container .why-choose-us-highlight-icon {
    width: 32px;
    height: 32px;
    color: var(--color-secondary-main);
}
.why-choose-us-highlight-title {
    font-size: 1.5em;
    font-weight: 600;
    color: var(--color-primary-dark);
    margin: 32px 0 16px 0;
    text-align: left;
}
.why-choose-us-highlight-body {
    font-size: 0.8em;
    color: var(--color-primary-dark);
    text-align: left;
}