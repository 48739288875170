.work-with-us-parent-container {
    background-color: var(--background-color-white);
    padding: 64px 32px;
}
.work-with-us-container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: auto;
}
.work-with-us-text-container {
    display: flex;
    flex-direction: column;
    
}
.work-with-us-text-container,
.work-with-us-form {
    flex: 1;
    width: 100%;
    min-width: 300px;
}
.work-with-us-form form {
    display: flex;
    flex-direction: column;
    align-items: end;
}
.work-with-us-form .submit_button {
    min-height: 36px;
    min-width: 128px;
}
.work-with-us-container .work-with-us-title {
    font-size: 2em;
    font-weight: 600;
    text-align: left;
}
.work-with-us-container .work-with-us-subtitle {
    font-size: 1em;
    text-align: left;
}
.work-with-us-container .work-with-us-icon {
    width: 100px;
    height: 100px;
    color: var(--color-primary-light);
}
.work-with-us-form .contact-form-input,
.work-with-us-form textarea {
    margin: 0 0 8px 0;
    width: 100%;
    background-color: var(--background-color-white);
}
.work-with-us-form .work-with-us-form-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 8px;
}
.work-with-us-form textarea {
    padding: 16px;
}