
.PreLoader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;

  padding: 128px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color-white);
}
.PreLoader .Title {
  font-size: 1.25rem;
  margin: 16px 0 0 0;
}
.PreLoader .Subtitle {
  font-size: 0.8rem;
  margin: 8px 0 32px 0;
}
.PreLoader .Graphics-container {
  margin-bottom: 16px;
  width: 100px;
  height: 100px;
  /* background-image: url('../../asset/icon/no_content.png'); */
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-repeat: contain;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.5;
}
.PreLoader Button {
  margin-top: 0 !important;
}