.package_size_page {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.package-size-selection-container {
    position: absolute;
    width: 90%;
    max-width: 600px;
    padding: 0 16px;
    border: solid 1px var(--color-secondary-main);
    border-radius: 16px;
    background-color: var(--background-color-off-white-90p);
    top: 100px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
}
.package-size-selection-container p {
    margin: 12px 0;
}
.package_size_container_parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    margin-top: 47px;
    overflow: hidden;
}
.package_size_container {
    position: relative;
}
.package_size_container .package-size-picture {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.package_size_container .map-hover-canvas {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: hard-light;
}
#package_size_container_2bhk,
#package_size_container_2bhk .package-size-picture,
#package_size_container_2bhk .map-hover-canvas {
    width: 1920px;
    height: 1278px;
}
#package_size_container_3bhk,
#package_size_container_3bhk .package-size-picture,
#package_size_container_3bhk .map-hover-canvas {
    width: 1920px;
    height: 1489px;
}
.room_hover_tooltip {
    /* scale: 4; */
    z-index: 1000;
    pointer-events: none;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    margin: 15px 0 0 15px;
    padding: 4px 12px;
    background-color: #fff;
    box-shadow: 0 0 8px #0009;
    border-radius: 16px;
}
.room_hover_tooltip h3 {
    font-size: 0.9em;
    font-weight: 600;
    color: var(--color-primary-dark);
    margin: 0;
}
.room_hover_tooltip p {
    font-size: 0.7em;
    font-weight: 500;
    color: var(--color-primary-dark);
    margin: 0;
}