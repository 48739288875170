.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.section-title {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-bottom: 32px;
}
.section-title-parent-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 80px;
}
.section-title-text {
  font-size: 4em;
  /* letter-spacing: 0.05em; */
  line-height: 0.75em;
  margin: 0 0 24px 0;
}
.section-subtitle-text {
  font-size: 1em;
  color: var(--color-primary-dark);
}
.section-title-divider {
  flex: 1;
  height: 2px;
  border-radius: 2px;
  background-color: var(--color-primary-main);
}
.floating-contact-form-container {
  z-index: 9999;
  position: fixed;
  width: 100vw;
  height: 100vh;
}
.floating-contact-button {
  z-index: 9999;
  position: fixed !important;
  right: 24px;
  bottom: 24px;
}
.floating-contact-form-inner-container {
  z-index: 9999;
  position: fixed !important;
  right: 24px;
  bottom: 24px;
  width: calc(100% - 48px);
  max-width: 400px;
  /* height: 100%; */
  max-height: 90vh;
  border-radius: 16px;
  overflow-y: scroll;
  background-color: var(--background-color-white);
  box-shadow: 0 0 48px #0003;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}
.floating-contact-form-inner-container form {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: left;
}
.floating-contact-form-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.floating-contact-form-header p {
  margin: 0;
}
.floating-contact-form-input {
  width: 100%;
}
.floating-contact-form-input.submit_button {
  min-height: 36px;
  min-width: 128px;
}
.floating-contact-form-item {
  width: 100%;
  margin-bottom: 8px;
}
.contact-details-acknowledge-input-container {
  margin-bottom: 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
