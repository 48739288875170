.projects-slider-container {
    padding: 64px 0 128px 0;
    border-bottom: solid 1px #0004;
}
.projects-slider-container .projects-slideshow {
    width: 100%;
}
.projects-slider-container .projects-slideshow .project-card {
    cursor: pointer;
    width: min(300px, 100%);
    height: 400px;
    position: relative;
    border: solid 1px #ad9a81;
    background-color: var(--color-secondary-dark);
    border-radius: 8px;
    box-shadow: 0px 0px 10px #0001;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    padding: 32px;
    margin: 10px 0;
    text-align: left;
}
.projects-slider-container .projects-slideshow .project-card .project-picture-container {
    position: relative;
    width: 100%;
    margin: 0 0 24px 0;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
}
.projects-slider-container .projects-slideshow .project-card .project-picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    opacity: 1;
    scale: 1.25;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
}
.projects-slider-container .projects-slideshow .project-card:hover .project-picture {
    scale: 1;
    opacity: 0.5;
}
.projects-slider-container .projects-slideshow .project-card .gradient-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(5deg,#56442c, #0000, #efd7ab);
    z-index: 2;
}
.projects-slider-container .projects-slideshow .project-card .project-details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    position: relative;
    z-index: 3;
    color: #fff;
}
.projects-slider-container .projects-slideshow .project-card .project-details .title {
    font-size: 2em;
    /* font-weight: 600; */
    margin: 0;
    color: #fff;
    text-shadow: 2px 2px 10px #0003;
}
.projects-slider-container .projects-slideshow .project-card .project-details .subtitle {
    font-size: 1em;
    color: var(--color-secondary-main);
}
.projects-slider-container .projects-slideshow .project-card .project-details .common-divider-light {
    margin: 12px 0;
}
.projects-slider-container  .project-details-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    /* border-top: solid 1px #fff5; */
}
.projects-slider-container  .project-details-container .project-details-item {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
}
.projects-slider-container  .project-details-container .project-details-icon {
    width: 24px;
    height: 24px;
    margin: 0 16px 0 0;
    color: var(--color-secondary-main);
}
.projects-slider-container  .project-details-container p {
    text-align: center;
    margin: 0;
    color: var(--color-secondary-main);
}