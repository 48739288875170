.partners-parent-container {
    width: 100%;
    background-color: var(--background-color-white);
}
.partners-parent-container .section-title {
    margin: auto;
    align-items: center;
}
.partners-parent-container .partners-inner-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}
.partners-partners-container {
    width: 100%;
    padding: 32px 0;
}
.partners-partners-section-title {
    font-size: 3em;
    margin: 32px 32px 64px 32px;   
}
.partners-partners-grid-view {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    grid-auto-flow: dense;
    grid-gap: 32px;
    margin: 32px 0;
    padding: 32px;
}
.partners-partners-grid-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.partners-partners-grid-item-picture {
    width: 150px;
    height: 150px;
    margin-right: 32px;
    border: solid 1px var(--color-secondary-main);
    border-radius: 8px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.partners-partners-grid-item-details {
    /* flex: 1; */
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.partners-partners-grid-item-details-title {
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 4px;
}
.partners-partners-grid-item-details-subtitle {
    font-size: 0.9em;
    opacity: 0.85;
    margin-bottom: 12px;
}