.package-selector-parent-container {
    width: 100%;
    height: 100vh;
}
.package-selector-loader {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.package-room-picture-preview {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-size: contain;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-repeat: contain;
    background-repeat: no-repeat;
    transition: background 0.5s ease-in-out;
}
.package-selection-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    margin: 4px;
    overflow: hidden;
}
.package-selector {
    display: inline-flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
}
.package-selector p {
    font-size: 0.75rem;
    margin-bottom: 0;
}
.package-selector .package-selector-title {
    font-size: 0.75rem;
    margin-bottom: 8px;
    color: #fff;
    background-color: #4449;
    padding: 4px 8px;
    border-radius: 6px;
}
.package-selector-toggle {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
}
.package-selector-toggle p {
    color: #fff;
    margin-left: 12px;
}
.package-selector-toggle img {
    width: 28px;
    height: 28px;
    filter: invert(100%);
}
.package-selector-toggle.active p {
    color: #000;
}
.package-selector-toggle.active img {
    filter: unset;
}
.package-selector input[type="radio"]:checked + label p {
    color: #000;
}
.package-selector .package-radio {
    padding: 6px 12px;
}
.MuiToggleButtonGroup-root {
    background-color: #444D;
    box-shadow: 0 0 5px 3px #4445;
}
.MuiToggleButton-root {
    color: #fff !important;
}
.MuiToggleButton-root.Mui-selected {
    background-color: #fffd !important;
    color: #000 !important;
}
#package-room-radio-group {
    width: 100%;
}
.css-ueukts-MuiButtonBase-root-MuiToggleButton-root,
.css-dqmlfn-MuiButtonBase-root-MuiToggleButton-root {
    border-color: #fff5 !important;
}
.MuiToggleButtonGroup-root  .package-selector-toggle p {
    transition-duration: 250ms;
}
.MuiToggleButtonGroup-root:hover  .package-selector-toggle p {
}
.room-selector .room-selector-inner-container {
    position: absolute;
    right: calc(-181px + 46px);
    width: 181px;
    transition-duration: 250ms;
}
.room-selector .room-selector-inner-container:hover {
    right: 0;
}

@media screen and (max-width: 800px) {
    /* Small Screen */
    .package-room-picture-preview {
        background-size: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        object-fit: cover;
    }
}