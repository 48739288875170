.before-after-parent-container {
    width: 100%;
    min-height: 100vh;
    /* padding: 36px 24px; */
    position: relative;
    background-color: var(--color-primary-dark);
    /* border: solid 1px var(--color-secondary-dark) */
}
.before-after-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.before-after-inner-container .section-title {
    position: relative;
    width: 100%;
    align-items: center;
    padding: 84px 24px 42px 24px;
    margin: 0;
}
.before-after-inner-container .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    object-fit: cover;
    object-position: top center;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 0.05;
}
.before-after-inner-container .section-title .section-title-text {
    position: relative;
    font-size: 2.5em;
    line-height: 1.25em;
    color: var(--color-secondary-dark);
}
.before-after-inner-container .section-title .section-subtitle-text {
    position: relative;
    color: var(--color-secondary-dark);
}
.before-after-section {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    position: relative;
}
.before-after-section:nth-child(2) {
    border-bottom: solid 2px var(--color-secondary-dark);
}
.before-after-section .picture {
    width: 100%;
    /* max-width: 250px; */
    height: 100%;
    /* max-height: 250px; */

    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    object-fit: cover;
    object-position: top center;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.before-after-section .text {
    width: 40%;
    max-width: 350px;
    height: 100%;
    padding: 0 0 0 8%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(90deg, #807360, transparent);
}
.before-after-section .text h3,
.before-after-section .text p {
    color: #fff;
    text-align: left;
}
.before-after-section .text h3 {
    font-size: 2.5em;
}
.before-after-section .text p {
    font-size: 1em;
}
@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    .before-after-inner-container .background,
    .before-after-section .picture {
        background-attachment: local;
    }   
}