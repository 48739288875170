.contact-request-result-parent-container {
    width: 100%;
    padding: 128px 0 0 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contact-request-result-parent-container .contact-request-result-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20vh;
}
.contact-request-result-body-section {
    padding: 0 32px;
}
.contact-request-result-inner-container .section-title {
    margin: auto;
    align-items: center;
}
.contact-request-result-inner-container .section-title .section-title-text {
    /* font-family: Raleway; */
    /* font-size: 3em; */
    line-height: 0.75em;
}
.contact-request-result-intro-tagline {
    width: 100%;
    margin: 32px;
    padding: 32px;
    border-radius: 12px;
    color: var(--color-secondary-main);
    background-color: var(--color-secondary-light);
}
.contact-request-result-intro-paragraph-container {
    padding: 48px 32px 128px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contact-request-result-directors-container {
    width: 100%;
    background-color: var(--background-color-white);
}
.contact-request-result-directors-section-title {
    font-size: 3em;
    margin: 64px 32px;
}
.contact-request-result-intro-paragraph {
    text-align: justify;
    margin: 0;
}
.contact-request-result-intro-paragraph~ul {
    margin: 32px 0;
}

.contact-request-result-directors-grid-view {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, max(250px, 100%/3)), 1fr));
    grid-auto-flow: dense;
    grid-gap: 64px;
    margin: 32px 0;
}
.contact-request-result-directors-grid-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}
.contact-request-result-directors-grid-item-header {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    margin-bottom: 24px;
}
.contact-request-result-directors-grid-item-picture {
    width: 170px;
    height: 200px;
    margin-right: 32px;
    border: solid 1px var(--color-secondary-main);
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 0 12px var(--color-secondary-main);
}
.contact-request-result-directors-grid-item-details {
    flex: 1;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.contact-request-result-directors-grid-item-details-title {
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 4px;
    text-align: left;
}
.contact-request-result-directors-grid-item-details-subtitle {
    font-size: 1.1em;
    opacity: 0.85;
    margin-bottom: 8px;
    text-align: left;
}
.contact-request-result-directors-grid-item-details-cta {
    /*  */
}
.contact-request-result-directors-grid-item-message {
    font-size: 1em;
    opacity: 0.9;
    text-align: justify;
}

.contact-request-result-employees-container {
    width: 100%;
}
.contact-request-result-employees-section-title {
    font-size: 3em;
    margin: 64px 32px;   
}
.contact-request-result-employees-grid-view {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%,max(120px, 100%/6)), 1fr));
    grid-auto-flow: dense;
    grid-gap: 32px;
    margin: 32px 0;
}
.contact-request-result-employees-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}
.contact-request-result-employees-grid-item-picture {
    width: 140px;
    height: 180px;
    margin-bottom: 16px;
    border: solid 1px var(--color-secondary-main);
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.contact-request-result-employees-grid-item-details-title {
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 0px;
}
.contact-request-result-employees-grid-item-details-subtitle {
    font-size: 0.9em;
    opacity: 0.85;
    margin-bottom: 8px;
}

.contact-request-result-partners-container {
    width: 100%;
    padding: 64px 0;
    background-color: var(--background-color-white);
}
.contact-request-result-partners-section-title {
    font-size: 3em;
    margin: 64px 32px;   
}
.contact-request-result-partners-grid-view {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%,max(120px, 100%/6)), 1fr));
    grid-auto-flow: dense;
    grid-gap: 32px;
    margin: 32px 0;
}
.contact-request-result-partners-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}
.contact-request-result-partners-grid-item-picture {
    width: 150px;
    height: 150px;
    margin-bottom: 8px;
    border: solid 1px var(--color-secondary-main);
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.contact-request-result-partners-grid-item-details-title {
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 4px;
}
.contact-request-result-partners-grid-item-details-subtitle {
    font-size: 0.9em;
    opacity: 0.85;
    margin-bottom: 12px;
}