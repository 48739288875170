.work-process-parent-container {
    width: 100%;
    padding: 82px 10%;
    background-color: var(--background-color-white);
}
.work-process-inner-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}
.work-process-main-content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%/2), 1fr));
    gap: 64px;
}
.work-process-pictures {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
}
.work-process-pictures .work-process-picture {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    object-fit: cover;
    min-height: min(280px, 30vh);
}
.work-process-pictures :nth-child(1) {
    grid-row: 1 / 3;
}
.work-process-section-title {
    font-size: 3em;
    /* letter-spacing: 0.05em; */
    line-height: 1em;
    margin: 0 0 16px 0;
}
.work-process-to-about-us-cta {
    text-decoration: none;
    margin-bottom: 32px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--color-primary-main);
}
.work-process-to-about-us-cta p {
    margin: 0 8px 0 0;
}
.work-process-to-about-us-cta .work-process-to-about-us-cta-icon {
    width: 20px;
    height: 20px;
}
.work-process-highlights-grid-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.work-process-highlight-grid-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 8px 0;
    gap: 24px;
    /* border: solid 1px var(--color-primary-translucent); */
}
.work-process-text-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.work-process-highlight-icon-container {
    padding: 16px;
    background-color: var(--background-color-white);
    border: solid 1px var(--color-primary-main);
    border-radius: 50%;
    scale: 1;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
}
.work-process-highlight-grid-item:hover .work-process-highlight-icon-container {
    scale: 1.25;
}
.work-process-highlight-icon-container .work-process-highlight-icon {
    width: 24px;
    height: 24px;
    color: var(--color-primary-light);
}
.work-process-highlight-title {
    font-size: 1.25em;
    font-weight: 600;
    color: var(--color-primary-dark);
    margin: 0 0 8px 0;
    text-align: left;
}
.work-process-highlight-body {
    font-size: 0.9em;
    color: var(--color-primary-dark);
    text-align: left;
    margin: 0;
}