.landing-screen-container {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: var(--color-secondary-dark); */
    /* overflow: hidden; */
}
.landing-screen-tagline {
    font-size: 1.5em;
    writing-mode: vertical-lr;
    rotate: 180deg;
    margin: 0;
    padding-left: 16px;
}
.landing-screen-bg-pattern {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    object-fit: cover;
    background-position: center;
    background-attachment: fixed;
    /* opacity: 0.25; */
}
.landing-screen-bg-video-background-1 {
    position: absolute;
    width: min(100vh,100vw);
    height: min(100vh,100vw);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 8px;
    rotate: 44deg;
    background-color: var(--color-secondary-dark);
    opacity: 0.35;
}
.landing-screen-bg-video-background-2 {
    position: absolute;
    top: 10px;
    right: 0px;
    left: 20px;
    bottom: -10px;
    border-radius: 8px;
    rotate: 5deg;
    background-color: var(--color-primary-main);
}
.landing-screen-inner-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 72px;
    /* padding: 8%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* gap: 10%; */
    /* opacity: 0; */
}
.landing-screen-bg-video-container {
    flex: 2;
    position: relative;
    width: 100%;
    min-width: 350px;
    max-width: 700px;
    /* height: 100%; */
    /* padding: 4px; */
    /* border-radius: 16px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 32px;
}
.landing-screen-bg-video {
    position: relative;
    width: 100%;
    /* height: 100%; */
    aspect-ratio: 16/12;
    border-radius: 6px;
    object-fit: cover;
    /* border: solid 2px var(--color-primary-main); */
}
.landing-screen-description-container {
    position: relative;
}
.landing-screen-text-container {
    position: relative;
    flex: 1;
    /* position: absolute; */
    /* width: 100%; */
    /* max-width: 720px; */
    /* height: 100%; */
    /* top: 0; */
    /* left: 0; */
    /* padding: 5% 5% 0 5%; */
    display: flex;
    flex-direction: column;
    align-items:end;
    justify-content: center;
    /* transition-duration: 3000ms; */
    transition-timing-function: ease-in-out;
    /* transition-delay: 5000ms; */
    margin: max(32px, 8%);
}
.landing-screen-section-title {
    font-size: 3em;
    /* letter-spacing: 0.05em; */
    line-height: 110%;
    color: var(--color-primary-main);
    margin: 0 0 32px 0;
    /* text-shadow: 1px 3px 2px #2d1a06e8; */
    text-align: right;
}
.landing-screen-section-subtitle {
    font-size: 2vh;
    /* letter-spacing: 0.1em; */
    max-width: 50em;
    color: var(--color-primary-main);
    opacity: 0.75;
    margin: 0 0 16px 0;
    /* text-shadow: 2px 2px 10px #000a; */
    text-align: right;
}
.landing-screen-text-container .MuiSvgIcon-root,
.landing-screen-text-container .MuiTypography-root {
    color: var(--color-primary-main);
}
.landing-screen-to-about-us-cta {
    text-decoration: none;
    margin-bottom: 32px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.landing-screen-to-about-us-cta p {
    margin: 0 8px 0 0;
    color: var(--color-secondary-main);
    text-shadow: 2px 2px 10px #000a;
}
.landing-screen-to-about-us-cta .landing-screen-to-about-us-cta-icon {
    width: 20px;
    height: 20px;
    color: var(--color-secondary-main);
}
.landing-page-social-media-links-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    right: max(28px,3%);
    bottom: max(42px,10%);
    /* transition-duration: 3000ms; */
    transition-timing-function: ease-in-out;
    /* transition-delay: 5000ms; */
}
.landing-page-social-media-links-container a {
    background-color: #0000;
    padding: 8px;
    border-radius: 8px;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
}
.landing-page-social-media-links-container a:hover {
    background-color: #0002;
}
.landing-page-social-media-links-container .landing-page-social-media-icon {
    color: var(--color-primary-main);
}
@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    .landing-screen-container,
    .landing-screen-bg-pattern {
        background-attachment: local;
    }   
}