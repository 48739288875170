.projects-parent-container {
    width: 100%;
    padding: 96px 0 128px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: var(--background-color-off-white); */
}
.projects-container {
    width: 100%;
    padding: 58px 0;
    /* background-color: var(--background-color-off-white); */
}
.projects-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.projects-inner-container .section-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.projects-inner-container .section-title .section-title-text {
    font-size: 4em;
    margin: 24px;
}
.projects-grid-view {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%,max(250px, 100%/6)), 1fr));
    grid-auto-flow: dense;
    grid-gap: 8px;
    padding: 32px 16px;
}
.projects-grid-view.featured {
    grid-template-columns: repeat(auto-fit, minmax(min(100%,max(250px, 100%/4)), 1fr));
}
.projects-section-title {
    width: 100%;
    text-align: left;
    font-size: 2em;
    font-weight: 600;
    margin: 32px 10% 0 10%;
    padding: 0 16px;
    color: var(--color-primary-main);
}
.project-grid-item {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 300px;
    border: solid 2px var(--color-primary-dark);
    border-radius: 2px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    text-align: left;
}
.project-grid-item .project-grid-item-picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    scale: 1;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
}
.project-grid-item:hover .project-grid-item-picture {
    scale: 1.05;
}
.project-grid-item .gradient-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -100%;
    width: 100%;
    /* background-image: linear-gradient(165deg,var(--color-primary-dark), var(--color-primary-main)); */
    background-color: var(--color-primary-main);
    z-index: 2;
    opacity: 0.9;
    transition: 350ms;
    transition-timing-function: ease-in-out;
}
.projects-grid-view.featured .gradient-container {
    opacity: 0;
}
.project-grid-item:hover .gradient-container {
    right: 0;
}
.projects-grid-view .project-grid-item .project-grid-item-details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -75%;
    padding: 16px 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    z-index: 3;
    opacity: 0;
    transition-duration: 350ms;
    transition-timing-function: ease-out;
}
.projects-grid-view.featured .project-grid-item .project-grid-item-details {
    background-color: var(--color-primary-main-90p);
}
.projects-grid-view.featured .project-grid-item .project-grid-item-details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: auto;
    opacity: 1;
}
.project-grid-item:hover .project-grid-item-details {
    left: 0;
    opacity: 1;
}
.project-grid-item .project-grid-item-details .title {
    width: 75%;
    font-size: 1.5em;
    font-weight: 600;
    color: var(--color-secondary-main);
    margin: 0;
}
.project-grid-item .project-grid-item-details .subtitle {
    width: 75%;
    font-size: 1em;
    color: var(--color-secondary-main);
    margin: 8px 0 0 0;
}
.project-grid-item .project-grid-item-details .explore-button {
    margin-top: 32px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--color-secondary-main);
}
.project-grid-item .project-grid-item-details .explore-button p {
    color: var(--color-secondary-main);
    opacity: 0.75;
    margin: 0 8px 0 0;
}
.project-grid-item.cta {
    border-color: var(--color-primary-main);
}
.project-grid-item.cta .gradient-container  {
    right: 0;   
    background-color: var(--color-primary-main);
}
.project-grid-item.cta .project-grid-item-details {
    left: 0;
    opacity: 1;
}
.project-grid-item.cta .project-grid-item-details .title,
.project-grid-item.cta .project-grid-item-details .subtitle  {
    color: #fff;
}
.project-grid-item.cta .project-grid-item-details .explore-button {
    background-color: var(--background-color-white);
    color: var(--color-primary-main);
    border-radius: 4px;
    padding: 8px 14px;
}
.project-grid-item.cta .project-grid-item-details .explore-button p {
    color: var(--color-primary-main);
    margin-right: 8px;
}