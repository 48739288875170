@font-face {
  font-family: 'Chopin';
  src: url('./fonts/ChopinScript.ttf') format('truetype');
}
@font-face {
  font-family: 'Bruney';
  src: url('./fonts/Bruney.ttf') format('truetype');
}
@font-face {
  font-family: 'Polenta';
  src: url('./fonts/Polenta.ttf') format('truetype'),
  url('./fonts/Polenta.woff') format('woff'),
  url('./fonts/Polenta.otf') format('opentype'),
  url('./fonts/Polenta.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  box-sizing: border-box;
  /* outline-color:red;
  outline-style: dotted; */
}
p, h1 {
  /* font-family: "Raleway", sans-serif; */
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: var(--color-primary-main);
}
:root {
  --color-primary-light: #8c6680;
  /* --color-primary-main: #5f1247;
  --color-primary-main-90p: #5f1247e5;
  --color-primary-dark: #380929; */
  --color-primary-main: #491539;
  --color-primary-main-90p: #491539ec;
  --color-primary-dark: #24081b;
  --color-primary-dark-90p: #1b0012e5;
  --color-primary-translucent: #5f12473b;
  --color-primary-text: #fff;
  
  --color-secondary-translucent: #fff9ecaa;
  --color-secondary-light: #fff9ec;
  --color-secondary-main: #b79e6a;
  --color-secondary-dark: #a58e64;
  --color-secondary-text: #000;

  --background-color-white: #f9f7f4;
  --background-color-off-white: #f1ede5;
  --background-color-off-white-90p: #f1ede599;
  
  --max-width: 420px;
}
html, body, #root {
  width: 100%;
  height: 100%;
  background-color: var(--color-primary-dark);
  -webkit-font-smoothing: antialiased;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-body, .parent-container, .App {
  width: 100%;
  min-height: 100%;
  background-color: var(--background-color-off-white);
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-repeat: contain;
  background-position: center;
  background-attachment: fixed;
  overflow-x: hidden;
}
.max-inner-width {
  max-width: 1280px;
  margin: auto;
}
.max-inner-width-reduced {
  max-width: min(calc(100% - 64px), 1280px);
  margin: auto;
}
.Page {
  width: 100%;
  height: 100vh;
}
.common-primary-divider {
  height: 4px;
  border-radius: 2px;
  width: 100%;
  max-width: 80px;
  background-color: var(--color-primary-light);
  margin: 24px 0;
}
.common-primary-divider.small {
  height: 2px;
  max-width: 40px;
  margin: 12px 0;
}
.common-secondary-divider {
  height: 2px;
  border-radius: 2px;
  width: 100%;
  max-width: 60px;
  background-color: var(--color-secondary-dark);
  margin: 24px 0 0 0;
}
.common-divider-light {
  height: 2px;
  border-radius: 2px;
  width: 100%;
  max-width: 40px;
  background-color: var(--color-secondary-light);
  margin: 16px 0;
}
.brand-font-primary {
  font-family: "Polenta", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Mui Overrides Start */
.MuiButton-containedPrimary {
  color: var(--color-secondary-main) !important;
}
/* Mui Overrides End */

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  .content-body, .parent-container, .App {
      background-attachment: local;
  }   
}